var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"artist-block"},[_c('menu-breadcrumb',[_c('el-breadcrumb-item',[_vm._v("藝術家管理")])],1),_c('div',{staticClass:"container"},[_c('table-title',[_c('p',[_vm._v(" 藝術家列表 "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'CreateArtist' })}}},[_vm._v("新增藝術家 ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.artist_data}},[_c('el-table-column',{attrs:{"prop":"active","label":"啟用","width":"80","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.active},on:{"change":function($event){return _vm.handleActive(row.artist_id)}}})]}}])}),_c('el-table-column',{attrs:{"prop":"avatar","label":"圖像","width":"200","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-avatar',{staticStyle:{"object-position":"center center"},attrs:{"size":60,"fit":"cover","src":_vm.handleAvatarUrl(row.avatar)}})]}}])}),_c('el-table-column',{attrs:{"prop":"artist_zh.name","label":"中文姓名","width":"200","sortable":""}}),_c('el-table-column',{attrs:{"prop":"artist_en.name","label":"英文姓名","width":"200","sortable":""}}),_c('el-table-column',{attrs:{"label":"中文簡歷","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow",attrs:{"title":row.artist_zh.introduction}},[_vm._v(" "+_vm._s(row.artist_zh.introduction)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('el-input',{attrs:{"size":"mini","placeholder":"搜尋藝術家名稱"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.setup.search),callback:function ($$v) {_vm.$set(_vm.setup, "search", $$v)},expression:"setup.search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleSearch},slot:"append"})],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-setting"},on:{"click":function($event){return _vm.$router.push({
                name: 'DetailArtist',
                params: { id: row.artist_id },
              })}}},[_vm._v("內容")]),_c('el-button',{attrs:{"type":"warning","size":"mini","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({
                name: 'EditArtist',
                params: { id: row.artist_id },
              })}}},[_vm._v("編輯")]),_c('el-button',{attrs:{"type":"danger","size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row['artist_id'])}}},[_vm._v("刪除")])]}}])})],1),_c('table-pagination',{attrs:{"total":_vm.total,"limit":_vm.setup.limit,"page":_vm.setup.page},on:{"change-page":_vm.handleChangePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="artist-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>藝術家管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          藝術家列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <el-button
          type="success"
          @click="$router.push({ name: 'CreateArtist' })"
          >新增藝術家
        </el-button>
      </table-title>

      <el-table :data="artist_data" v-loading="loading">
        <el-table-column prop="active" label="啟用" width="80" sortable>
          <template v-slot="{ row }">
            <el-checkbox
              :value="row.active"
              @change="handleActive(row.artist_id)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="avatar" label="圖像" width="200" sortable>
          <template v-slot="{ row }">
            <el-avatar
              :size="60"
              fit="cover"
              style="object-position: center center"
              :src="handleAvatarUrl(row.avatar)"
            >
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column
          prop="artist_zh.name"
          label="中文姓名"
          width="200"
          sortable
        ></el-table-column>
        <el-table-column
          prop="artist_en.name"
          label="英文姓名"
          width="200"
          sortable
        ></el-table-column>
        <el-table-column label="中文簡歷" sortable>
          <template v-slot="{ row }">
            <div class="text-overflow" :title="row.artist_zh.introduction">
              {{ row.artist_zh.introduction }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="編輯" fixed="right" width="260">
          <template #header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="搜尋藝術家名稱"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>

          <template #default="{ row }">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-setting"
              @click="
                $router.push({
                  name: 'DetailArtist',
                  params: { id: row.artist_id },
                })
              "
              >內容</el-button
            >
            <el-button
              type="warning"
              size="mini"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditArtist',
                  params: { id: row.artist_id },
                })
              "
              >編輯</el-button
            >
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="handleDelete(row['artist_id'])"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  deleteArtist,
  updateArtistActive,
  getArtistsPagination,
} from "@/api/artist";

export default {
  name: "Artist",
  data() {
    return {
      artist_data: [],
      total: 0,
      setup: {
        limit: 25,
        page: 0,
        search: "",
      },
      loading: true,
    };
  },
  created() {
    if (this.$route.query.p) {
      this.setup.page = +this.$route.query.p;
    }
    this.handleGetArtists();
  },
  methods: {
    async handleGetArtists() {
      this.loading = true;
      const { results, total } = await getArtistsPagination(this.setup);

      this.total = total;
      this.artist_data = results;

      this.loading = false;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetArtists();
    },
    handleSearch() {
      this.setup.page = 0;

      this.handleGetArtists();
    },
    async handleActive(artistId) {
      const index = this.artist_data.findIndex(
        ({ artist_id }) => artist_id === artistId
      );

      if (index !== -1) {
        this.artist_data[index].active = !this.artist_data[index].active;

        await updateArtistActive(artistId, {
          active: this.artist_data[index].active,
        });

        this.$message({
          type: "success",
          message: "更新成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "更新失敗",
        });
      }
    },
    handleDelete(artistId) {
      this.$confirm("是否刪除該角色", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteArtist(artistId);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          await this.handleGetArtists();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
    handleAvatarUrl(url) {
      return `${process.env.VUE_APP_IMAGE_API}/${url}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-avatar {
  img {
    width: 100%;
  }
}
</style>
